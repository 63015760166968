import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import { Sidebar2023 } from '../SchoolProgram2023/components';


const CulturalFiesta2023 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });
    
    const [config, setConfig] = useState({ base_image_archive_url: '' });
    useEffect(() => {
      fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
        .then(response => response.json())
        .then(data => setConfig(data))
        .catch(error => console.error('Error fetching config:', error));
    }, []);
    
    const p1 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/Cultural_Fiesta/1.webp`;
    const p2 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/Cultural_Fiesta/2.webp`;
    const p3 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/Cultural_Fiesta/3.webp`;
    const p4 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/Cultural_Fiesta/4.webp`;
    const p5 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/Cultural_Fiesta/5.webp`;
    const p6 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/Cultural_Fiesta/6.webp`;
    const p7 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/Cultural_Fiesta/7.webp`;
    const p8 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/Cultural_Fiesta/8.webp`;
    const p9 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/Cultural_Fiesta/9.webp`;
    const p10 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/Cultural_Fiesta/10.webp`;
    const p11 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/Cultural_Fiesta/11.webp`;
    const p12 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/Cultural_Fiesta/12.webp`;
    const p13 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/Cultural_Fiesta/13.webp`;
    const p14 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/Cultural_Fiesta/14.webp`;
    const p15 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/Cultural_Fiesta/15.webp`;
    const p16 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/Cultural_Fiesta/16.webp`;
    const p17 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/Cultural_Fiesta/17.webp`;
    const p18 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/Cultural_Fiesta/18.webp`;
    const p19 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/Cultural_Fiesta/19.webp`;
    const p20 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/Cultural_Fiesta/20.webp`;
    const p21 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/Cultural_Fiesta/21.webp`;
    const p22 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/Cultural_Fiesta/22.webp`;
    const p23 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/Cultural_Fiesta/23.webp`;
    const p24 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/Cultural_Fiesta/24.webp`;
    const p25 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/Cultural_Fiesta/25.webp`;
    
    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
        {
          src: p3,
          source: p3,
          rows: 1,
          cols: 1,
      },
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        },
        {
            src: p5,
            source: p5,
            rows: 1,
            cols: 1,
        },
        {
          src: p6,
          source: p6,
          rows: 1,
          cols: 1,
      },
        {
          src: p7,
          source: p7,
          rows: 1,
          cols: 1,
      },
        {
            src: p8,
            source: p8,
            rows: 1,
            cols: 1,
        },
        {
          src: p9,
          source: p9,
          rows: 1,
          cols: 1,
     },
        {
            src: p10,
            source: p10,
            rows: 1,
            cols: 1,
        },
        {
            src: p11,
            source: p11,
            rows: 1,
            cols: 1,
        },
        {
            src: p12,
            source: p12,
            rows: 1,
            cols: 1,
        },
        {
          src: p13,
          source: p13,
          rows: 1,
          cols: 1,
      },
        {
            src: p14,
            source: p14,
            rows: 1,
            cols: 1,
        },
        {
            src: p15,
            source: p15,
            rows: 1,
            cols: 1,
        },
        {
          src: p16,
          source: p16,
          rows: 1,
          cols: 1,
      },
        {
          src: p17,
          source: p17,
          rows: 1,
          cols: 1,
      },
        {
            src: p18,
            source: p18,
            rows: 1,
            cols: 1,
        },
        {
          src: p19,
          source: p19,
          rows: 1,
          cols: 1,
     },
        {
            src: p20,
            source: p20,
            rows: 1,
            cols: 1,
        },
        {
            src: p21,
            source: p21,
            rows: 1,
            cols: 1,
        },
        {
            src: p22,
            source: p22,
            rows: 1,
            cols: 1,
        },
        {
          src: p23,
          source: p23,
          rows: 1,
          cols: 1,
      },
        {
            src: p24,
            source: p24,
            rows: 1,
            cols: 1,
        },
        {
            src: p25,
            source: p25,
            rows: 1,
            cols: 2,
        },
    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                CULTURAL FIESTA
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Date: 15 DECEMBER 2023
                                </Typography>

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                National Public School Yeshwanthpur commemorated its illustrious 10-year journey with a Cultural Fiesta, an opulent showcase of mesmerizing performances by both Pre-primary and Classes 1-5 students on the evening of Friday, December 15, 2023. The event was graced by the eminent personality Mr. Prakash Belwadi, a luminary artist both in theatre and cinema, who adorned the occasion as the Chief Guest. The presence of distinguished Principals from various institutions added a touch of prestige to the celebration.
                                    <br></br>
                                    The ceremonial initiation involved the chief guest and management ceremoniously lighting the lamp, followed by an elegant invocation dance and a melodious performance by the talented students of Classes 4 and 5. Addressing the gathering with profound insights, the Chairman welcomed and acknowledged the presence of the esteemed Chief Guest and Principal appreciated the effort of teachers for their dedicated service and encouraged the students to put up a wonderful show.  
                                    <br></br>
                                    The Pre-Primary and Kindergarten segment unfolded as a spectacular narrative, depicting a child's journey through life, underscoring the ultimate goal of nurturing responsible citizens. The thematic structure comprised distinct phases—Beginning, Connection, Learning, Progress and the Path to Unity.
                                    <br></br>
                                    The event commenced with an enchanting portrayal of children's connection with God through a graceful dance, depicting Lord Krishna's early life, executed by Mont-1 and Pre-K students. The subsequent segment, "Connection," explored children's bond with parents through a captivating dance drama centred on Lord Ganesh, performed brilliantly by Mont-2 and Pre Nursery students. The Learning segment showcased a visual symphony as KG 1 students beautifully connected with teachers through a dance set to the popular Hindi song "English Vinglish." Progress was depicted through a mesmerizing dance, offering a child's perspective on society, skilfully enacted by Mont-3 students. The grand finale, "The Path Ahead," symbolizing children learning to embrace Unity in Diversity, left a lasting impression on the Pre-Primary section.
                                    <br></br>
                                    Prior to the commencement of the cultural odyssey of   Classes 1-5, the school paused to award its distinguished alumni who had demonstrated exceptional success in their board exams, there by bringing pride and  glory to the institution. Furthermore, this served as a special moment to express gratitude and honour the steadfast commitment of the teachers who had played an integral role in the school evolution from its inception to the present day.  
                                    <br></br>
                                    The G20 summit showcased India's cultural richness and achievements in Science and Sports. Class 4 students highlighted Kerala's dance forms, while Class 5 portrayed Karnataka's cultural tapestry. Moving east, Classes 2 and 3 depicted West Bengal and the North East, showcasing social reformers and traditional dances. The journey continued to Rajasthan and Gujarat, with Class 4 presenting traditional dances, and Class 2 re-enacting the Dandi March. The northern region featured Punjab and Delhi, with Class 1 presenting Punjab's culture and Class 5 showcasing Akbar's Durbar and a Kathak dance. The cultural odyssey culminated with Class 3 celebrating Indian sports achievements in a mesmerizing presentation for G20 delegates.
                                    <br></br>
                                    A deep feeling of pride embraced the audience, parents were delighted to witness their children's remarkable performances. The celebration echoed as a well-coordinated symphony, skilfully blending the elements of academic commitment, cultural diversity and shared applause, thus leaving an unforgettable mark in the school's prestigious history.
                                </Typography>
                            </Box>
                            {/* <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “Christmas is the season for kindling the fire of hospitality.”
                                <br />
                            </Typography> */}

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 300 : 200}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                    <Box marginBottom={4}>
                            <Sidebar2023/>
                        </Box> 

                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default CulturalFiesta2023;